import debounce from 'lodash/debounce';
import { FC, useCallback, useState } from 'react';
import AsyncSelect from 'react-select/async';
import addressSanitization from '~/helpers/addressSanitization';
import { setNewRelicNoticeError } from '~/helpers/newrelic';
import { trackEvent } from '~/helpers/tracking';
import addressFormatter from '../../helpers/addressFormatter';
import slugify from '../../helpers/slugify';
import { theme } from '../../styles';
import { Address } from '../../types/address';
import Null from '../Null';
import Option from './Option';
import config from '~/constants/config';

const selectStyles = {
  control: (styles) => ({
    ...styles,
    backgroundColor: theme.colors.primary.white,
    height: '50px',
    paddingLeft: '13px',
  }),
  valueContainer: (styles) => ({
    ...styles,
    borderRight: 'none',
    padding: 0,
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  placeholder: (styles) => ({
    ...styles,
    margin: 0,
    display: 'block',
    color: theme.colors.primary.darkBlue,
  }),
  singleValue: (styles) => ({
    ...styles,
    margin: 0,
  }),
  menu: (styles) => ({
    ...styles,
    margin: 0,
  }),
  option: (styles, { isDisabled }) => {
    return {
      ...styles,
      alignItems: 'baseline',
      color: theme.colors.primary.darkBlue,
      cursor: isDisabled ? 'not-allowed' : 'pointer',
      display: 'flex',
      padding: '16px',
    };
  },
};

interface Props {
  apiVersion: string;
  selectedAddress: Address;
  setSelectedAddress: (address: Address) => void;
  placeholder?: string;
}

const AutoComplete: FC<Props> = ({ apiVersion, selectedAddress, setSelectedAddress, placeholder }) => {
  const [suggestions, setSuggestions] = useState([]);

  const loadOptions = useCallback(
    debounce((inputValue, callback) => {
      if (inputValue.match(/^\d/) !== null && inputValue.length >= 3) {
        const sanitizedInputValue = addressSanitization(inputValue);
        fetch(
          `${config.api}${apiVersion}/serviceability/predictive?address=${encodeURIComponent(
            sanitizedInputValue
          )}&inFootPrint=false&serviceAddressRecord=false&excludeChildren=false`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            },
          }
        )
          .then(async (res) => {
            const addresses = await res.json();
            if (Array.isArray(addresses)) {
              const sug = addresses.map((address) => {
                let label = addressFormatter(address?.addressLine1, address?.addressLine2);
                label += ` - ${address.city} ${address.stateProvince} ${address.zipCode}`;

                return {
                  addressKey: address.addressKey,
                  address: address.addressLine1,
                  address2: address?.addressLine2,
                  city: address.city,
                  state: address.stateProvince,
                  zip: address.zipCode,
                  zip4: address.zipCode4,
                  value: slugify(label),
                  label,
                  latitude: address.latitude,
                  longitude: address.longitude,
                  parsedAddress: address.parsedAddress,
                };
              });
              setSuggestions(sug);
              callback(sug);
            }
          })
          .catch(error => 
            {
              setNewRelicNoticeError(error); 
              trackEvent({
                action: 'clientErrored',
                data: {
                 errorMessage: error?.message,
                 errorClass: error?.name
                },
                
              })
            });
      } else if (suggestions.length) {
        setSuggestions([]);
      }
    }, 500),
    [apiVersion]
  );

  const handleAddressOnChange = (address, reason) => {
    if (typeof address === 'string') {
      if (reason.action !== 'input-blur' && reason.action !== 'menu-close') {
        setSelectedAddress({
          label: address,
          address: address,
          value: slugify(address),
        });
      }
    } else if (address.address) {
      trackEvent({
        action: 'elementClicked',
        data: {
          text: 'Suggestion Click',
          elementType: 'LI',
          position: 'BODY',
          action: 'UPDATE',
        },
      });
      setSelectedAddress({
        ...address,
        address: addressFormatter(address?.address, address?.address2),
      });
    }
  };

  return (
    <div className="address-form__inputs address-form__inputs--auto-complete">
      <div className="address-form__input address-form__input--auto-complete" data-testid="street-address" data-private="lipsum">
        <label className="sr-only" htmlFor="street-address">
          Enter your address to check availability
        </label>
        <AsyncSelect
          inputId="street-address"
          classNamePrefix="address-form"
          placeholder={placeholder || 'Enter your address'}
          options={suggestions}
          defaultOptions={suggestions}
          loadOptions={loadOptions}
          styles={selectStyles}
          noOptionsMessage={() => null}
          value={selectedAddress?.address?.length ? selectedAddress : ''}
          inputValue={selectedAddress?.label || ''}
          onInputChange={handleAddressOnChange}
          onChange={handleAddressOnChange}
          components={{
            DropdownIndicator: Null,
            Option,
          }}
        />
      </div>
    </div>
  );
};

AutoComplete.displayName = 'AutoComplete';

export default AutoComplete;
