import { FC } from 'react';
import { components, OptionProps } from 'react-select';
import MapMarker from '~/components/Icons/fill/MapMarker';

const Option: FC<OptionProps> = (props) => {
  const { data } = props;
  return (
    <components.Option {...props}>
      <MapMarker />
      {data.label}
    </components.Option>
  );
};

Option.displayName = 'Option';

export default Option;
